import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InspectionDocument } from '../models/inspection-document.interface';
import { firstValueFrom } from 'rxjs';
import { VehicleInspectionService } from 'src/app/vehicle-inspection/services/vehicle-inspection.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminInspectionService {
  public isEditingInspection = false;

  public editedInspection: InspectionDocument;

  public isCreatingNewInspection = false;

  newInspection: InspectionDocument | null = null;

  private token: string | undefined;

  private apiUrl = `${environment.vehicleInspectionUrl}/v1/inspection`;

  constructor(
    private httpClient: HttpClient,
    private vehicleInspectionService: VehicleInspectionService,
  ) {}

  async getToken(): Promise<string | undefined> {
    if (this.token) {
      return this.token;
    }

    const storedToken = localStorage.getItem('inspectionToken');
    if (storedToken) {
      this.token = storedToken;

      return this.token;
    }

    this.token = await this.vehicleInspectionService.authenticate();

    if (this.token) {
      localStorage.setItem('inspectionToken', this.token);
    }

    return this.token;
  }

  private async getAuthHeaders(): Promise<{ [key: string]: string }> {
    const token = await this.getToken();

    if (!token) {
      throw new Error('No authentication token available');
    }

    return {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };
  }

  async fetchInspections(): Promise<InspectionDocument[]> {
    try {
      const headers = await this.getAuthHeaders();

      return await firstValueFrom(
        this.httpClient.get<{ data: InspectionDocument[] }>(this.apiUrl, {
          headers,
        }),
      ).then((response) => response.data);
    } catch (error) {
      console.error(
        '[AdminInspectionService] Error fetching inspections:',
        error,
      );

      this.clearToken();

      return [];
    }
  }

  async uploadNewInspection(newInspection: InspectionDocument): Promise<any> {
    try {
      const headers = await this.getAuthHeaders();

      return await firstValueFrom(
        this.httpClient.post<void>(this.apiUrl, newInspection, {
          headers,
        }),
      );
    } catch (error) {
      console.error(
        '[AdminInspectionService] Error uploading new inspection:',
        error,
      );

      this.clearToken();
      throw error;
    }
  }

  async uploadUpdatedInspection(
    updatedInspection: InspectionDocument,
  ): Promise<any> {
    try {
      const headers = await this.getAuthHeaders();
      const endpoint = `${this.apiUrl}/${updatedInspection.reference}`;

      return await firstValueFrom(
        this.httpClient.put<void>(endpoint, updatedInspection, {
          headers,
        }),
      );
    } catch (error) {
      console.error(
        '[AdminInspectionService] Error updating inspection:',
        error,
      );

      this.clearToken();

      throw error;
    }
  }

  clearToken() {
    this.token = undefined;

    localStorage.removeItem('inspectionToken');
  }
}
