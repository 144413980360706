import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { enableProdMode } from '@angular/core';
import { Capacitor } from '@capacitor/core';

enableProdMode();
defineCustomElements(window);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (
  Capacitor.getPlatform() === 'web' &&
  !window.location.hostname.includes('localhost')
) {
  const script = document.createElement('script');
  script.src =
    'https://consent.cookiefirst.com/sites/movacarpro.com-bcdfeaf6-9bbf-48a6-b4aa-97fc7e3c4398/consent.js';

  document.head.appendChild(script);
}
